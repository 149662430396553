<template>
  <div class="table-responsive">
    <table v-if="fetched && messages.length" class="table">
      <thead>
        <tr>
          <th>{{$t('generic-str.date')}}</th>
          <th>ID</th>
          <th>{{$tc('generic-str.message', 1)}}</th>
          <th>{{$t('generic-str.customer')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="message in messages" :key="message.id">
          <td>{{ message.scheduled_at }}</td>
          <td class="max-200">{{ message.id }}</td>
          <td
            class="max-200"
            v-tooltip.top="`${message.body}`"
            @click="copy"
            role="button"
          >
            {{ message.body }}
          </td>
          <td>{{ message.account.name }}</td>
          <td>
            <div class="input-group">
              <div class="input-group-prepend">
                <button
                  @click="approve(message.id)"
                  class="btn btn-success"
                  v-tooltip.top="'Aprovar Mensagem'"
                >
                  <i class="icon dripicons-thumbs-up color-white"></i>
                </button>
                <button
                  @click="disapprove(message.id)"
                  class="btn btn-danger border-radius-right"
                  v-tooltip.top="'Bloquear Mensagem'"
                >
                  <i class="icon dripicons-thumbs-down color-white"></i>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else-if="fetched" class="text-center table-placeholder">
      <i class="fas fa-envelope font-size-80"></i>
      <h5 class="card-title m-t-20">{{$t('anti-fraud-component.none-msg')}}</h5>
    </div>
    <div v-else class="qt-block-ui relative" style="padding: 120px" />
    <br />
    <pagination :lastPage="pages" @change="fetch" />
  </div>
</template>

<script>
// @ is an alias to /src
import AntifraudService from '@/services/admin/antifraud.service';
import Pagination from '@/components/Pagination.vue';
import Swal from 'sweetalert2';

export default {
  name: 'AntifraudQueued',
  components: {
    Pagination,
  },
  data() {
    return {
      fetched: false,
      tickets: [],
      messages: [],
      id: '',
      form: {
        sort: 'scheduled_at:desc',
        page: 1,
      },
      pages: 1,
      totalMessages: 0,
      exporting: false,
      isSending: false,
      opens: [],
      opensPercent: 0,
      answeredPercent: 0,
      answered: [],
    };
  },
  computed: {
    info() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch(1);
    setInterval(() => {
      this.fetch(1);
    }, 60000);
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      AntifraudService.getQueued(this.form).then(
        (response) => {
          this.fetched = true;
          this.messages = response.data;
          this.pages = response.last_page;
          this.totalMessages = response.total;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    approve(messageId) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          'Todas as mensagens com esse conteúdo serão liberadas. Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Liberar!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          AntifraudService.approveMessage(messageId).then(
            () => {
              this.$emit('validated');
              this.fetch(1);
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    approveUrls(messageId) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          'Todas as mensagens com essas urls serão liberadas. Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Liberar!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          AntifraudService.approveUrls(messageId).then(
            () => {
              this.$emit('validated');
              this.fetch(1);
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    disapprove(messageId) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          'Todas as mensagens com esse conteúdo serão bloqueadas. Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Bloquear!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          AntifraudService.disapproveMessage(messageId).then(
            () => {
              this.$emit('validated');
              this.fetch(1);
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    copy(e) {
      navigator.clipboard.writeText(e.target.innerText.trim());

      this.$toast.show({
        title: 'Mensagem copiada',
        type: 'success',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
td a i {
  font-size: 1.19rem;
}
</style>
