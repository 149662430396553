<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6">
        <button class="btn btn-success" v-modal="{ target: 'word-modal' }">
          {{$t('anti-fraud-component.register-word')}}
        </button>
      </div>
      <div class="col-12 col-md-6">
        <form @submit.prevent="fetch(1)">
          <div class="form-group">
            <div class="input-group mb-3">
              <input
                v-model="form.search"
                type="text"
                :placeholder="`${$t('generic-str.search-by.default')}...`"
                aria-label=""
                aria-describedby="basic-addon2"
                class="form-control"
              />
              <div class="input-group-append">
                <button
                  type="submit"
                  class="btn btn-primary btn-icon btn-search-id"
                >
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="fetched && words.length" class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>{{$t('generic-str.date')}}</th>
                <th>{{$tc('generic-str.word', 1)}}</th>
                <th>Status</th>
                <th>{{$t('generic-str.customer')}}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="word in words" :key="word.id">
                <td>{{ word.created_at | date('DD/MM/YYYY HH:mm:ss') }}</td>
                <td class="max-200">{{ word.word }}</td>
                <td>
                  <span
                    v-if="word.status == 'whitelisted'"
                    class="badge badge-success"
                    >{{$t('generic-str.status.lbl-released')}}</span
                  >
                  <span
                    v-else-if="word.status == 'blacklisted'"
                    class="badge badge-danger"
                    >{{$t('generic-str.status.lbl-blocked-o')}}</span
                  >
                </td>
                <td>
                  <span v-if="word.account">{{ word.account.name }}</span>
                  <span v-else>{{$t('generic-str.all')}}</span>
                </td>
                <td>
                  <button
                    @click="destroy(word.id)"
                    class="btn btn-danger btn-remove"
                  >
                    <i class="icon dripicons-trash color-white"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="fetched" class="text-center table-placeholder">
          <i class="fas fa-envelope font-size-80"></i>
          <h5 class="card-title m-t-20">{{$t('warnings.anti-fraud.none-word')}}</h5>
        </div>
        <div v-else class="qt-block-ui relative" style="padding: 120px" />
        <br />
        <pagination :lastPage="pages" @change="fetch" />
      </div>
    </div>
    <word-modal @sent="fetch(1)" id="word-modal"></word-modal>
  </div>
</template>

<script>
import AntifraudService from '@/services/admin/antifraud.service';
import Pagination from '@/components/Pagination.vue';
import WordModal from '@/components/antifraud/WordModal.vue';
import Swal from 'sweetalert2';

export default {
  name: 'AntifraudWords',
  components: {
    Pagination,
    WordModal,
  },
  data() {
    return {
      fields: [
        'created_at',
        'word',
        'account',
        'status',
        'actions',
      ],
      fetched: false,
      words: [],
      pages: 1,
      form: {
        search: '',
        page: 1,
      },
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      AntifraudService.getAntifraudWords(this.form).then(
        (response) => {
          this.fetched = true;
          this.words = response.data;
          this.pages = response.last_page;
          this.totalMessages = response.total;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    destroy(wordId) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          this.$t('warnings.anti-fraud.label-word'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          AntifraudService.deleteWord(wordId).then(
            () => {
              this.fetch(1);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, `${this.$t('warnings.anti-fraud.deleted-word')}.`, 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>
