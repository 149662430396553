<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6 offset-md-6">
        <form @submit.prevent="fetch(1)">
          <div class="form-group">
            <div class="input-group mb-3">
              <input
                v-model="form.search"
                type="text"
                :placeholder="`${$t('generic-str.search-by.default')}...`"
                aria-label=""
                aria-describedby="basic-addon2"
                class="form-control"
              />
              <div class="input-group-append">
                <button type="submit" class="btn btn-primary btn-icon">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="fetched && messages.length" class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>{{$t('generic-str.date')}}</th>
                <th>ID</th>
                <th>{{$tc('generic-str.message', 1)}}</th>
                <th>{{$t('generic-str.customer')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="message in messages" :key="message.id">
                <td>{{ message.created_at | date('DD/MM/YYYY HH:mm:ss') }}</td>
                <td class="max-200">{{ message.id }}</td>
                <td
                  class="max-200"
                  v-tooltip.top="`${message.message}`"
                  @click="copy"
                  role="button"
                >
                  {{ message.message }}
                </td>
                <td>
                  <span
                    v-if="message.is_approved"
                    class="badge badge-success"
                    >{{$t('generic-str.status.lbl-released')}}</span
                  >
                  <span
                    v-else
                    class="badge badge-danger"
                    >{{$t('generic-str.status.lbl-blocked-o')}}</span
                  >
                </td>
                <td>
                  <button
                    @click="destroy(message.id)"
                    class="btn btn-danger btn-remove"
                  >
                    <i class="icon dripicons-trash color-white"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="fetched" class="text-center table-placeholder">
          <i class="fas fa-envelope font-size-80"></i>
          <h5 class="card-title m-t-20">{{$t('anti-fraud-component.none-msg')}}</h5>
        </div>
        <div v-else class="qt-block-ui relative" style="padding: 120px" />
        <br />
        <pagination :lastPage="pages" @change="fetch" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AntifraudService from '@/services/admin/antifraud.service';
import Pagination from '@/components/Pagination.vue';
import Swal from 'sweetalert2';

export default {
  name: 'AntifraudMessages',
  components: {
    Pagination,
  },
  data() {
    return {
      fetched: false,
      tickets: [],
      messages: [],
      id: '',
      form: {
        sort: 'scheduled_at:desc',
        page: 1,
      },
      pages: 1,
      totalMessages: 0,
      exporting: false,
      isSending: false,
      opens: [],
      opensPercent: 0,
      answeredPercent: 0,
      answered: [],
    };
  },
  computed: {
    info() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch(1);
  },
  methods: {
    goDetail(message) {
      this.$router.push({ name: 'TicketMessage', params: { m: message } });
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      AntifraudService.getAntifraudMessages(this.form).then(
        (response) => {
          this.fetched = true;
          this.messages = response.data;
          this.pages = response.last_page;
          this.totalMessages = response.total;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    approve(messageId) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          'Todas as mensagens com esse conteúdo serão liberadas. Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Liberar!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          AntifraudService.approveMessage(messageId).then(
            () => {
              this.fetch(1);
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    disapprove(messageId) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          'Todas as mensagens com esse conteúdo serão bloqueadas. Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Bloquear!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          AntifraudService.disapproveMessage(messageId).then(
            () => {
              this.fetch(1);
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    copy(e) {
      navigator.clipboard.writeText(e.target.innerText.trim());

      this.$toast.show({
        title: 'Mensagem copiada',
        type: 'success',
      });
    },
    destroy(messageId) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: 'A Mensagem será removida. Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, remover!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          AntifraudService.deleteMessage(messageId).then(
            () => {
              this.fetch(1);
              Swal.fire('Removida!', 'A mensagem foi removida.', 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
td a i {
  font-size: 1.19rem;
}
</style>
